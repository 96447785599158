* {
    box-sizing: border-box;
}

html {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0, #f7f7f7, #c5c5c5);
}

#background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .5;
}

#logo {
    flex: 0 0 auto;
    width: 512px;
    max-width: 100%;
    max-height: 80vh;
    // opacity: 0;
    --colorShapeM: hsl(0deg 100% 73%);
    --colorShapeL: hsl(16deg 100% 73%);

    .part, .shade {
        opacity: 0;
        transition: all 1200ms cubic-bezier(0.78, 0.14, 0.29, 0.96);
        transition-property: opacity
    }

    #Outline {
        stroke: #333;
        stroke-width: 0;
        opacity: 1;
        stroke-dashoffset: -2048;

        transition: all 1200ms cubic-bezier(0.78, 0.14, 0.29, 0.96);
        transition-property: stroke-width, stroke-dasharray, stroke-dashoffset;
    }

    &.showOutline {
        #Outline {
            stroke-width: 3;
            stroke-dasharray: 2048;
            stroke-dashoffset: 0;
        }
    }

    &.shades {
        .shade {
            opacity: 1;
        }
    }

    &.parts {
        .part {
            opacity: 1;
        }   
    }

    &.hideOutline {
        #Outline {
            stroke-width: 0;
            // transition-duration: 1200ms;
            // stroke-dasharray: -2048;
            // stroke-dashoffset: 2048;
            // opacity: 0;
            // transition-duration: 1200ms;
        }
    }
}

#socials {
    display: none;
}

main {
    display: flex;
    justify-content: center;
}